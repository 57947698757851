<template lang="pug">
  .create-account-form
    AppOverlayLoader(:state="loading")
    .row.create-account-form-row(
      :data-cy="$t('account_management.create_account.fields.last_name')"
    )
      .create-account-form-row-label
        FormFieldLabel(
          :title="$t('account_management.create_account.fields.last_name')"
          required
        )
      .create-account-form-field
        BFormInput(
          type="text"
          name="last-name"
          v-model="account.last_name"
          :placeholder="$t('account_management.create_account.placeholders.last_name')"
          :state="$v.account.last_name.$error ? false : null"
        )
        BFormInvalidFeedback(v-if="$v.account.last_name.$error") {{ $t("validations.at_least_1_letter") }}
    .row.create-account-form-row(
      :data-cy="$t('account_management.create_account.fields.first_name')"
    )
      .create-account-form-row-label
        FormFieldLabel(
          :title="$t('account_management.create_account.fields.first_name')"
          required
        )
      .create-account-form-field
        BFormInput(
          type="text"
          name="first-name"
          v-model="account.first_name"
          :invalid="$v.account.first_name.$error"
          :placeholder="$t('account_management.create_account.placeholders.first_name')"
          :state="$v.account.first_name.$error ? false : null"
          aria-describedby="input-first-name"
        )
        BFormInvalidFeedback(v-if="$v.account.first_name.$error") {{ $t("validations.at_least_1_letter") }}
    .row.create-account-form-row(
      :data-cy="$t('account_management.create_account.fields.account_id')"
    )
      .create-account-form-row-label
        FormFieldLabel(
          :title="$t('account_management.create_account.fields.account_id')"
          required
        )
      .create-account-form-field
        .d-flex.align-items-center.w-100
          BFormInput(
            :disabled="isEdit"
            type="text"
            name="account-id"
            v-model="account.account_id"
            :placeholder="$t('account_management.create_account.placeholders.account_id')"
            :state="$v.account.account_id.$error || !isEmpty(accountIdErrors) ? false : null"
            @blur="checkAccountIdAlreadyExists"
            @input="cleanAccountIdErrors"
            @keyup="handleAccountIdKeyup"
          )
          FaIcon.m-l-10(
            v-if="accountIdAlreadyExistsLoading"
            icon="spinner"
            spin
          )
        BFormInvalidFeedback(
          v-if="isInvalidAccountAccountId"
          :state="false"
        ) {{ $t("validations.at_least_1_letter") }}
        BFormInvalidFeedback(
          v-if="accountIdErrors"
          v-for="error in accountIdErrors"
          :key="error"
          :state="false"
        ) {{ error }}

    .row.create-account-form-row(
      :data-cy="$t('account_management.create_account.fields.password')"
    )
      .create-account-form-row-label
        FormFieldLabel(
          :title="$t('account_management.create_account.fields.password')"
          required
        )
      .create-account-form-field
        AppInputPassword(
          v-model="account.password"
          :placeholder="$t('account_management.create_account.placeholders.password')"
          :invalid="isInvalidAccountPassword"
        )
    .row.create-account-form-row(
      :data-cy="$t('account_management.create_account.fields.password_confirmation')"
    )
      .create-account-form-row-label
        FormFieldLabel(
          :title="$t('account_management.create_account.fields.password_confirmation')"
          required
        )
      .create-account-form-field
        AppInputPassword(
          v-model="account.password_confirmation"
          name="password-confirmation"
          :placeholder="$t('account_management.create_account.placeholders.password_confirmation')"
          :invalid="isInvalidAccountPasswordConfirmation"
        )
        .password-errors(v-if="isInvalidAccountPassword || isInvalidAccountPasswordConfirmation")
          li(v-if="passwordRequiredError") {{ passwordRequiredError }}
          li(v-if="minLengthPasswordError") {{ minLengthPasswordError }}
          li(v-if="equalConfirmationPasswordError") {{ equalConfirmationPasswordError }}

    .row.create-account-form-row(
      :data-cy="$t('account_management.create_account.fields.email')"
    )
      .create-account-form-row-label
        FormFieldLabel(
          :title="$t('account_management.create_account.fields.email')"
          required
        )
      .create-account-form-field
        BFormInput(
          type="email"
          name="email"
          v-model="account.email"
          :placeholder="$t('account_management.create_account.placeholders.email')"
          :state="$v.account.email.$error ? false : null"
        )
        BFormInvalidFeedback(v-if="$v.account.email.$error") {{ $t("validations.invalid_email") }}
    .row.create-account-form-row(
      :data-cy="$t('account_management.create_account.fields.phone_number')"
    )
      .create-account-form-row-label
        FormFieldLabel(:title="$t('account_management.create_account.fields.phone_number')")
      .create-account-form-field
        BFormInput(
          type="tel"
          name="phone-number"
          v-model="account.phone_number"
          :placeholder="$t('account_management.create_account.placeholders.phone_number')"
          :state="$v.account.phone_number.$error ? false : null"
        )
        BFormInvalidFeedback(v-if="$v.account.phone_number.$error") {{ $t("validations.invalid_phone_number") }}
    .row.create-account-form-row(
      v-if="!isOtaAdminList"
      :data-cy="$t('account_management.create_account.fields.organization')"
    )
      .create-account-form-row-label
        FormFieldLabel(
          :title="organizationFieldLabel"
          required
        )
      .create-account-form-field-organizations
        AppDropdown(
          v-if="!isOrganizationsAdminList"
          close-on-select
          :items="organizations"
          :value="currentOrganization"
          :class="{ invalid: $v.account.organization_id.$error }"
          @select="selectOrganization"
        )
        AppDropdown(
          v-else
          :followReferenceBoundary="false"
          checkbox
          multiple
          searchable
          order-direction="keep"
          title-key="name"
          :loading="organizationsLoading"
          :value="selectedOrganizations"
          :items="organizations"
          @select="handleSelectOrganization"
        )
        BFormInvalidFeedback.m-l-6.d-block(v-if="$v.account.organization_id.$error") {{ $t("validations.invalid_user_organization") }}
    .row.create-account-form-row(
      v-else
      :data-cy="$t('account_management.create_account.fields.ota')"
    )
      .create-account-form-row-label
        FormFieldLabel(
          :title="$t('account_management.create_account.fields.ota')"
          required
        )
      .create-account-form-field
        FormFieldLabel(
          v-if="isEdit"
          :title="selectedOta.name"
        )
        AppDropdown(
          v-else
          allow-empty
          close-on-select
          value-key="id"
          title-key="name"
          :placeholder="$t('components.checkboxes_group.nothing_selected')"
          :value="selectedOta"
          :items="availableOtas"
          @select="selectOta"
        )

    .row.errors
      BAlert(
        v-if="!!errors"
        :show="isErrorsAlertShown"
        variant="danger"
        dismissible
      )
        p(v-for="error in errors") {{ error }}
      .col
        .d-flex.justify-content-end
          AppSaveButton.m-t-20(
            title="actions.confirm"
            :disabled="loading"
            @save="saveUserAccount"
          )
</template>

<script>
  // store modules
  import organizationsModule from "@/config/store/maestro/organizations"
  import maestroAccountsModule from "@/config/store/maestro/accounts"
  import accountsModule from "@/config/store/accounts"

  //mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withValidations from "@/mixins/withValidations"
  import withPasswordValidationErrors from "@/mixins/withPasswordValidationErrors.js"
  import withOrganizationAdminList from "@/mixins/withOrganizationAdminList"
  import withOtaAdminList from "@/mixins/withOtaAdminList"

  // misc
  import {
    EMAIL_REGEXP,
    PHONE_NUMBER_REGEXP,
    ACCOUNT_FORBIDDEN_SYMBOLS_REGEXP,
    OTAS_WITH_COMPANY_SYSTEM,
    ALERT_TIMEOUT
  } from "@/config/constants"
  import { mapGetters } from "vuex"
  import { find, isEmpty, map } from "lodash-es"
  import { appDebounce } from "@/helpers/common"

  const organizationsMixin = withStoreModule(organizationsModule, {
    name: "accountManagementOrganizations",
    readers: { organizations: "items", organizationsLoading: "loading" }
  })

  const maestroAccountsMixin = withStoreModule(maestroAccountsModule, {
    name: "maestro_accounts",
    actions: {
      fetchAccounts: "FETCH_ITEMS",
      createAccount: "CREATE_ITEM",
      updateAccount: "UPDATE_ITEM"
    },
    mutations: { setLoading: "SET_LOADING" }
  })

  const accountsMixin = withStoreModule(accountsModule, {
    name: "accounts",
    actions: { accountIdAlreadyExistsRequest: "CHECK_ACCOUNT_ID_EXISTS" }
  })

  const validationsMixin = withValidations(({ required, minLength }) => ({
    account: {
      first_name: {
        required,
        minLength: minLength(1)
      },
      last_name: {
        required,
        minLength: minLength(1)
      },
      account_id: {
        required,
        minLength: minLength(1)
      },
      password: {
        requireCheck(value) {
          if (!this.isEdit) {
            return !!value
          } else {
            return true
          }
        },
        minLength: minLength(8),
        sameAs: (value, self) => value === self.password_confirmation
      },
      password_confirmation: {
        requireCheck(value) {
          if (!this.isEdit) {
            return !!value
          } else {
            return true
          }
        },
        minLength: minLength(8),
        sameAs: (value, self) => value === self.password
      },
      email: {
        required,
        emailCheck: value => EMAIL_REGEXP.test(value)
      },
      phone_number: {
        phoneCheck: value => value === "" || PHONE_NUMBER_REGEXP.test(value)
      },
      ota_id: {
        requireCheck(value) {
          if (this.isOtaAdminList) {
            return !!value
          } else {
            return true
          }
        }
      },
      organization_id: {
        requireCheck(value) {
          if (this.isOrganizationsAdminList || this.isOtaAdminList) {
            return true
          } else {
            return !!value
          }
        }
      },
      organization_ids: {
        requireCheck(organization_ids) {
          if (this.isOrganizationsAdminList) {
            return organization_ids.length > 0
          } else {
            return true
          }
        }
      }
    }
  }))

  const defaultUserAttributes = {
    first_name: "",
    last_name: "",
    account_id: "",
    password: "",
    password_confirmation: "",
    email: "",
    phone_number: "",
    organization_id: "",
    organization_admin: false,
    ota_id: null,
    ota_admin: false,
    owner: true,
    allowed_shop_ids: [],
    organization_ids: []
  }

  export default {
    components: {
      AppSaveButton: () => import("@/components/elements/AppButton/Save"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      FormFieldLabel: () => import("@/components/elements/FormFieldLabel"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader"),
      AppInputPassword: () => import("@/components/elements/AppInputPassword")
    },

    mixins: [
      organizationsMixin,
      maestroAccountsMixin,
      accountsMixin,
      validationsMixin,
      withPasswordValidationErrors,
      withOrganizationAdminList,
      withOtaAdminList
    ],

    props: {
      currentAccount: {
        type: Object,
        required: false
      }
    },

    mounted() {
      this.$store.dispatch("FETCH_OTA_LIST")
    },

    data() {
      const editAccount = this.currentAccount || {}
      const organization_ids = this.currentAccount?.organizations?.map(({ id }) => id) || []

      return {
        account: { ...defaultUserAttributes, ...editAccount, organization_ids },
        errors: [],
        isErrorsAlertShown: false,
        accountLoading: false,
        accountIdErrors: [],
        accountIdAlreadyExistsLoading: false,
        isEdit: !!this.currentAccount
      }
    },

    computed: {
      ...mapGetters(["translatedOtaList"]),

      availableOtas({ translatedOtaList }) {
        return translatedOtaList.filter(({ rawName }) => OTAS_WITH_COMPANY_SYSTEM.includes(rawName))
      },

      selectedOta() {
        return find(this.availableOtas, ({ id }) => id === this.account.ota_id)
      },

      currentOrganization() {
        return find(this.organizations, { id: this.account.organization_id })
      },

      loading({ accountLoading, organizationsLoading }) {
        return organizationsLoading || accountLoading
      },

      selectedOrganizations({ account: { organization_ids } }) {
        return organization_ids.map(id => find(this.organizations, { id }))
      },

      organizationFieldLabel({ isOrganizationsAdminList }) {
        let label = ""

        if (isOrganizationsAdminList) {
          label = this.$t("account_management.create_account.fields.organizations")
        } else {
          label = this.$t("account_management.create_account.fields.organization")
        }

        return label
      }
    },

    methods: {
      isEmpty,

      selectOrganization(organization) {
        this.account.organization_id = organization.id
      },

      selectOta({ id }) {
        this.account.ota_id = id
      },

      async saveUserAccount() {
        this.accountLoading = true
        this.validateAttributes()

        if (!this.isValidAccount) {
          this.accountLoading = false
          return
        }

        this.removeEmptyPasswordAttrs()
        this.prepareTypeOfUser()

        try {
          if (this.isEdit) {
            await this.updateAccount(this.account)
          } else {
            await this.createAccount(this.account)
          }
          this.cancelValidation()
          this.fetchAccounts()
          this.$emit("close")
          this.accountLoading = false
        } catch (e) {
          this.accountLoading = false
        }
      },

      showErrorAlert(errors) {
        this.errors = errors
        this.isErrorsAlertShown = true

        setTimeout(() => {
          this.isErrorsAlertShown = false
        }, ALERT_TIMEOUT)
      },

      removeEmptyPasswordAttrs() {
        const { password, password_confirmation, ...accountData } = this.account

        if (password === "" || password_confirmation === "") {
          this.account = accountData
        }
      },

      prepareTypeOfUser() {
        if (this.isOtaAdminList || this.isOrganizationsAdminList) {
          this.account = {
            ...this.account,
            owner: false,
            ota_admin: this.isOtaAdminList,
            organization_admin: this.isOrganizationsAdminList
          }
        } else {
          this.account = {
            ...this.account,
            owner: true,
            ota_admin: false,
            organization_admin: false
          }
        }
      },

      checkAccountIdAlreadyExists(event) {
        const accountId = event.target.value
        this.cleanAccountIdErrors()

        this.validateAccountAccountId()

        if (this.isValidAccountAccountId) {
          this.accountIdAlreadyExistsLoading = true
          this.checkAccountIdAlreadyExistsDebounced(accountId)
        }
      },

      checkAccountIdAlreadyExistsDebounced: appDebounce(function(account_id) {
        this.accountIdAlreadyExistsRequest(account_id)
          .then(() => (this.accountIdErrors = [this.$t("validations.account_id_already_exists")]))
          .catch(() => (this.accountIdErrors = undefined))
          .finally(() => (this.accountIdAlreadyExistsLoading = false))
      }),

      cleanAccountIdErrors() {
        this.accountIdErrors = undefined
      },

      handleAccountIdKeyup(event) {
        const { value } = event.currentTarget
        const replacedValue = value.replace(ACCOUNT_FORBIDDEN_SYMBOLS_REGEXP, "")
        this.account.account_id = replacedValue
      },

      handleSelectOrganization(organizations) {
        this.account.organization_ids = map(organizations, ({ id }) => id)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .create-account
    margin-top: 50px
    color: $default-black

    .col-xxl-6
      @media (min-width: 1599px)
        flex: 0 0 50%
        max-width: 50%

    &.loading
      opacity: 0.7
      cursor: not-allowed

    &-heading
      color: $default-purple
      font-weight: 300
      font-size: 1.4rem
      padding-bottom: 30px
      padding-left: 25px

    &-form
      position: relative

      &-row
        align-items: flex-start
        display: flex
        margin: 0 0 20px 0
        width: 100%

        &-label
          width: 170px

        span
          font-size: 0.8rem

      &-field
        padding: 0 6px
        width: 234px

        .password-errors
          color: $default-red
          font-size: 80%
          margin-top: 0.25rem

        &-organizations
          width: 234px

          ::v-deep
            .app-select
              margin: 0

              button
                font-size: 0.8rem
                height: 32px
                padding: 6px 12px

              &:not(.opened)
                padding-top: 0
                padding-bottom: 0

                .dropdown-toggle
                  border-color: $default-purple

        input
          border-color: $default-purple
          font-size: 0.8rem

          &.is-invalid
            border-color: $default-red

  .errors
    .alert
      width: 100%
</style>
